<template>
  <div class="ds-wrapper mt-4">
    <vs-row class=" rounded-2xl md-rounded-3xl" vs-w="12" vs-align="flex-start" vs-type="flex" vs-justify="center">
      <vs-col vs-md="12" vs-sm="12">
        <div class="card-title mb-4 text-center">
          <h2 class="text-center text-primary uppercase mb-6">{{ requestOption == "pay-later" && paymentMethodDescription ? paymentMethodDescription.paymentSectionHeading : "Payment Method" }}</h2>
          <p class="mt-8" v-if="requestOption == 'pay-later' && paymentMethodDescription && paymentMethodDescription.paymentMethodDescription" v-html="paymentMethodDescription.paymentMethodDescription">
          </p>
        </div>
      </vs-col>
      <vs-col vs-md="6" vs-sm="12" class="max-w-420" v-if="['CARD', 'DIRECT_DEBIT'].includes(tabIndex) || (['EFT'].includes(tabIndex) && requestOption == 'pay-now')">
        <div class="flex flex-row justify-center gap-4">
          <div class="w-1/2 md:w-2/5" @click="selectTab('CARD')" v-if="paymentMethods.includes('CARD')">
            <vs-chip :class="`w-full tabs-chip ${ tabIndex == 'CARD' ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
              <CardPayIcon class="chip-icon" />
              <div class="chip-text">Card</div>
            </vs-chip>
          </div>
          <div class="w-1/2 md:w-2/5" @click="selectTab('DIRECT_DEBIT')" v-if="paymentMethods.includes('DIRECT_DEBIT')">
            <vs-chip :class="`w-full tabs-chip ${ tabIndex == 'DIRECT_DEBIT' ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
              <DirectDebitIcon class="chip-icon" />
              <div class="chip-text">Direct debit</div>
            </vs-chip>
          </div>
          <div class="w-1/2 md:w-2/5" @click="selectTab('EFT')" v-if="paymentMethods.includes('EFT')">
            <vs-chip :class="`w-full tabs-chip ${ tabIndex == 'EFT' ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
              <BankTransferIcon class="chip-icon" />
              <div class="chip-text">Bank transfer</div>
            </vs-chip>
          </div>
        </div>
        <hr class="dashed-border my-8" />
        <div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 'CARD'">
          <div class="flex md:flex-row flex-wrap justify-center gap-x-3 gap-y-2">
            <div v-for="card_option in card_options" :key="card_option._id">
            <div class="text-center" :class="cardType === card_option.card.slug || cardType === 'default' ? '' : 'opacity-30'">
              <img v-if="card_option.card.slug == 'visa'" :src="visaCard" alt="visa" />
              <img v-else-if="card_option.card.slug == 'mastercard'" :src="masterCard" alt="masterCard" />
              <img v-else-if="card_option.card.slug == 'amex'" :src="amexCard" alt="amex" />
              <img v-else-if="card_option.card.slug == 'diners'" :src="dinersClubCard" alt="diners-club" />
              <img v-else-if="card_option.card.slug == 'unionpay'" :src="unionpayCard" alt="unionpay" />
              <div class="text-xs font-light leading-tight" v-if="!SurChargable">{{ addZeroes(card_option.surcharge) }}%</div>
            </div>
            </div>
          </div>
          <div class="w-full">
            <vs-input name="cardHolder" label="Name on card" v-model="formData.name" :success="cardNameValid" :class="errors.has('cardHolder') ? 'hasError' : ''" icon="done" icon-after="true" icon-size="small" v-validate="'required|cardNameFormat'" data-vv-as="name on card" @change="checkFieldValidation('cardName')" autocomplete="off" />
            <span class="span-text-validation-danger" v-if="errors.has('cardHolder')"><span class="span-text-validation">{{ errors.first("cardHolder") }}</span></span>
          </div>
          <div class="w-full">
            <div class="custom-input-field" :class="[!cardValid || !isCardAllowed || showCardNumberIsRequired ? 'hasError' : '', cardFocused ? 'isFocused' : '', cardSuccess ? 'isValid' : '']" >
              <label for="number_div" class="input-label">Card number</label>
              <div id="number_div" style="height: 28px"></div>
              <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
            </div>
            <span class="span-text-validation-danger" v-if="!isCardAllowed && !showCardNumberIsRequired"><span class="span-text-validation">This card type is not supported. Please try another card</span></span>
            <span class="span-text-validation-danger" v-if="showCardNumberIsRequired"><span class="span-text-validation">Card number is required</span></span>
            <span class="span-text-validation-danger" v-if="!cardValid && !showCardNumberIsRequired && isCardAllowed"><span class="span-text-validation">Invalid card number</span></span>
          </div>
          <div class="flex flex-row gap-x-6">
            <div class="w-1/2">
              <vs-input class="input-arial" :class="showExpDateIsRequired || showExpired ? 'hasError' : ''" label="Expiry date" placeholder="MM/YY" v-mask="'##/##'" v-model="formData.card_expiry_date" :success="dateSuccess" icon="done" icon-after="true" icon-size="small" @keyup.native="handleExpiryCardField" @blur="handleExpiryCardFieldOnBlur" />
              <span class="span-text-validation-danger" v-if="showExpDateIsRequired"><span class="span-text-validation">Card expiry date is required</span></span>
              <span class="span-text-validation-danger" v-else-if="showExpired"><span class="span-text-validation">Expiry date is invalid</span></span>
            </div>
            <div class="w-1/2">
              <div class="custom-input-field" :class="[securityFocused ? 'isFocused' : '', showCvcIsRequired ? 'hasError' : '', cvvSuccess ? 'isValid' : '', isInvalidCvv ? 'hasError' : '' ]">
                <label class="input-label">
                  Security code
                  <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}">
                    <template slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></template>
                    <div class="popper py-2 px-4 text-center" v-if="cardType == 'amex'">
                      <p class="text-primary">The 4 digits on the front of your card.</p>
                      <div class="flex flex-row gap-x-4">
                        <div><img :src="amex1" alt="" /></div>
                      </div>
                    </div>
                    <div class="popper py-2 px-4" v-else-if="['visa', 'mastercard'].includes(cardType)">
                      <p class="text-primary">The 3 digits on the back of your card.</p>
                      <div class="flex flex-row gap-x-4">
                        <div><img :src="general1" alt="" /></div>
                      </div>
                    </div>
                    <div class="popper py-2 px-4 text-center" v-else>
                      <p>For American Express (left), the 4 digits on the front of your card.</p>
                      <p>For all other cards (right), the 3 digits on the back of your card.</p>
                      <div class="flex flex-row pt-2 gap-x-4">
                        <div><img :src="amex" alt="" /></div>
                        <div><img :src="general" alt="" /></div>
                      </div>
                    </div>
                  </popper>
                </label>
                <div id="cvv_div" style="height: 28px"></div>
                <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
              </div>
              <span class="span-text-validation-danger" v-if="showCvcIsRequired"><span class="span-text-validation">Security code is required</span></span>
              <span class="span-text-validation-danger" v-else-if="isInvalidCvv"><span class="span-text-validation">Security code is invalid</span></span>
            </div>
          </div>
          <!-- IMPORT PAYMENT METHOD SUMMARY -->
          <payment-method-summary :request-option="requestOption" :amount="purchaseAmount" :fee-amount="feeCharge" :total="totalCharge" :selected-plan="selectedPlan" :transactions="transactions" />
          <hr class="dashed-border mt-2 mb-2" />

          <div class="flex flex-row items-start mt-6 custom-quillEditor legals-custom payment-legals">
            <vs-checkbox v-model="payNowAcceptance" name="payNowAcceptance"> </vs-checkbox>
            <p class="ancillary-regular cursor-pointer terms-view-content ql-editor" @click="acceptPayNowTerms" v-html="payNowLegals.explicitTerms"></p>
          </div>
          <div v-if="page_type === 'paylater' && formData.payment_type !== 'pay-now'" class="flex flex-row items-start mt-2 mb-5 custom-quillEditor legals-custom payment-legals">
            <vs-checkbox  v-model="toc_acceptance" name="toc_acceptance"> </vs-checkbox>
            <p class="ancillary-regular cursor-pointer terms-view-content ql-editor" @click="acceptTerms" v-html="payLaterLegals.explicitTerms"></p>
          </div>

          <error-message v-if="displayErrorMessage" :message="failureReason" />
          <div v-if="showPayNow && showDirectPayNowFromApiConfig && !hidePayNow && requestOption == 'pay-now'">
            <vs-button color="primary" class="w-full mb-4" size="large" @click="submitNormalOrRecurringPayment" :disabled="handlePayNowDisable">{{ page_type === "recurring" ? "Register payment method" : `Pay $${addZeroes(getPayNowAmount)} now` }}</vs-button>
          </div>
          <div v-if="page_type === 'paylater' && formData.payment_type !== 'pay-now'">
            <vs-button color="primary" class="w-full mb-4" size="large" @click="submitPayLaterPayment" :disabled="handlePayLaterDisable">{{ selectedPlan.summaryButtonText || "Accept plan" }}</vs-button>
          </div>
        </div>
        <div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 'DIRECT_DEBIT'">
          <div class="w-full">
            <vs-input name="accountName" label="Account name" v-model="formData.accountName" :success="accountNameValid" :class="errors.has('accountName') ? 'hasError' : ''" icon="done" icon-after="true" icon-size="small" maxlength="32" v-validate="'required|min:2'" data-vv-as="account name" @change="checkFieldValidation('accountName')" autocomplete="off" />
            <span class="span-text-validation-danger" v-if="errors.has('accountName')"><span class="span-text-validation">{{ errors.first("accountName") }}</span></span>
          </div>
          <div class="flex flex-row gap-x-6">
            <div class="w-2/5">
              <vs-input
                label="BSB"
                name="bsb"
                :class="!bsbError ? 'hasError' : ''"
                placeholder="000 - 000"
                v-mask="'###-###'"
                v-model="formData.bsb"
                :success="bsbSuccess"
                icon="done"
                icon-after="true"
                icon-size="small"
                v-validate="'required'"
                @blur="handleBsb"
                autocomplete="off"
              />
              <span class="span-text-validation-danger" v-if="errors.has('bsb')"><span class="span-text-validation">{{ errors.first("bsb") }}</span></span>
              <span class="span-text-validation-danger" v-if="!showBsbValid"><span class="span-text-validation">Invalid BSB</span></span>
            </div>
            <div class="w-3/5">
              <vs-input name="accountNumber" label="Account number" v-model="formData.accountNumber" :success="accountNumberValid" :class="errors.has('accountNumber') ? 'hasError' : ''" icon="done" icon-after="true" icon-size="small" v-validate="'required|alphanumFormat'" data-vv-as="account number" @change="checkFieldValidation('accountNumber')" autocomplete="off" />
              <span class="span-text-validation-danger" v-if="errors.has('accountNumber')"><span class="span-text-validation">{{ errors.first("accountNumber") }}</span></span>
            </div>
          </div>

          <!-- IMPORT PAYMENT METHOD SUMMARY -->
          <payment-method-summary :request-option="requestOption" :amount="purchaseAmount" :fee-amount="feeCharge" :total="totalCharge" :selected-plan="selectedPlan" :transactions="transactions" />
          <hr class="dashed-border mt-2 mb-2" />

          <div class="flex flex-row items-start mt-6 custom-quillEditor legals-custom payment-legals">
            <vs-checkbox v-model="toc_acceptance_direct_debit" name="toc_acceptance_direct_debit"> </vs-checkbox>
            <p class="ancillary-regular cursor-pointer terms-view-content ql-editor" @click="acceptTermsNPP" v-html="directDebitLegals.explicitTerms"></p>
          </div>
          <div class="flex flex-row items-start mb-5 custom-quillEditor legals-custom payment-legals">
            <vs-checkbox v-model="toc_acceptance" name="toc_acceptance"> </vs-checkbox>
            <p class="ancillary-regular cursor-pointer terms-view-content ql-editor" @click="acceptTerms" v-html="payLaterLegals.explicitTerms"></p>
          </div>
          <error-message v-if="displayErrorMessage" :message="failureReason" />
          <div>
            <vs-button color="primary" class="w-full mb-4" size="large" @click="submitPayLaterPayment" :disabled="handlePayLaterDisable">{{ selectedPlan.summaryButtonText || "Accept plan" }}</vs-button>
          </div>
        </div>
        <div class="flex flex-col gap-y-8  mb-8" v-if="tabIndex == 'EFT'">
          <div>
            <div class="text-center mb-6 md:mb-5" v-if="eftDetails.eftDescription">
              <p class="text-sm">{{ eftDetails.eftDescription }}</p>
            </div>
            <div class="text-center mb-6 md:mb-5">
              <p class="text-sm">Transfer amount</p>
              <h2>${{ addZeroes(purchaseAmount) }}</h2>
            </div>
            <div class="flex flex-col gap-y-4 md:gap-y-3">
              <div class="flex flex-col gap-y-4 md:gap-y-3" v-if="eftDetails.partnerEftType == 'PLATFORM_BANK_ACCOUNT'">
                <div class="flex flex-row items-center gap-x-6" v-if="eftDetails.payId">
                  <p class="text-sm text-right w-1/2 payid-logo"><img :src="payId" alt="payId" height="20" class="mr-2" />PayID</p>
                  <p class="text-primary text-left w-1/2 mt-1">{{ eftDetails.payId }}</p>
                </div>
                <div class="flex flex-row items-center gap-x-6" v-if="eftDetails.reference">
                  <p class="text-sm text-right w-1/2">Use this reference</p>
                  <p class="text-primary text-left w-1/2">{{ eftDetails.reference }}</p>
                </div>
                <h2 class="my-2 text-center text-body">OR</h2>
              </div>
              <div class="flex flex-row gap-x-6" v-if="eftDetails.accountName">
                <div class="w-1/2 align-top">
                  <p class="text-sm text-right margin-top-3">Account name</p>
                </div>
                <div class="w-1/2">
                  <p class="text-primary text-left">{{ eftDetails.accountName }}</p>
                </div>
              </div>
              <div class="flex flex-row items-center gap-x-6" v-if="eftDetails.bsb">
                <p class="text-sm text-right w-1/2">BSB</p>
                <p class="text-primary text-left w-1/2">{{ eftDetails.bsb }}</p>
              </div>
              <div class="flex flex-row items-center gap-x-6" v-if="eftDetails.accountNumber">
                <p class="text-sm text-right w-1/2">Account number</p>
                <p class="text-primary text-left w-1/2">{{ eftDetails.accountNumber }}</p>
              </div>
              <div class="flex flex-row items-center gap-x-6" v-if="eftDetails.reference">
                <p class="text-sm text-right w-1/2">Use this reference</p>
                <p class="text-primary text-left w-1/2">{{ eftDetails.reference }}</p>
              </div>
              <div class="flex flex-row items-center gap-x-6" v-if="eftDetails.email">
                <p class="text-sm text-right w-1/2">Send confirmation to</p>
                <p class="text-primary text-left w-1/2">{{ eftDetails.email }}</p>
              </div>
              <div class="mt-8" v-if="eftDetails.partnerEftType == 'PLATFORM_BANK_ACCOUNT'">
                <p class="text-body text-center">
                  If the BSB/Account number isn't found on your internet banking portal, it's likely because you haven't made many payments to {{ appName }} previously - this is normal and won't impact your payment, but you can contact us to double check the details if you prefer.
                </p>
                <p class="text-body text-center mt-2">
                  Your bank may show the account owner as “Monoova”, who are {{ appName }}'s banking service provider.
                </p>
              </div>
              <hr class="dashed-border my-8" />
            </div>
            <p class="text-body text-center mb-4">IMPORTANT: Please use the <b>reference</b> above in your payment, to avoid delay or issues in routing your funds correctly.</p>
            <h3 class="text-body text-center">Thank you!</h3>
          </div>
        </div>
      </vs-col>
      <vs-col vs-md="12" v-else>
        <div class="flex flex-col gap-y-8 mb-8 mt-4" v-if="paymentMethods.length == 1 && paymentMethods.includes('EFT')">

          <!-- IMPORT PAYMENT METHOD SUMMARY -->
          <payment-method-summary :request-option="requestOption" :amount="purchaseAmount" :fee-amount="feeCharge" :total="totalCharge" :selected-plan="selectedPlan" :transactions="transactions" />
          <hr class="dashed-border mt-2 mb-2" />
          <div class="flex flex-row items-start mb-5 custom-quillEditor legals-custom payment-legals">
            <vs-checkbox v-model="toc_acceptance" name="toc_acceptance"> </vs-checkbox>
            <p class="ancillary-regular cursor-pointer terms-view-content ql-editor" @click="acceptTerms" v-html="payLaterLegals.explicitTerms"></p>
          </div>
          <error-message v-if="displayErrorMessage" :message="failureReason" />
          <div>
            <vs-button color="primary" class="w-full mb-4" size="large" @click="submitPayLaterPayment" :disabled="handlePayLaterDisable">{{ selectedPlan.summaryButtonText || "Accept plan" }}</vs-button>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  import { mapActions } from "vuex";
  import moment from "moment";
  import Popper from 'vue-popperjs';
  import { mask } from "vue-the-mask";

  import BankTransferIcon from "../../components/design-sys-components/icons/BankTransferIcon";
  import CardPayIcon from "../../components/design-sys-components/icons/CardPayIcon";
  import DirectDebitIcon from "../../components/design-sys-components/icons/DirectDebitIcon";
  import ToolTipIcon from "../../components/design-sys-components/icons/ToolTipIcon";
  import PaymentMethodSummary from "./PaymentMethodSummary";
  import ErrorMessage from "./ErrorMessage";
  import { sentryLog } from "../../../helper/sentryLog";

  const visaCard = require("@/assets/images/cards/visa.svg");
  const masterCard = require("@/assets/images/cards/mastercard.svg");
  const amexCard = require("@/assets/images/cards/amex.svg");
  // Tooltip images
  const amex = require("@/assets/images/cards/amex.png");
  const amex1 = require("@/assets/images/cards/amex-1.png");
  const general = require("@/assets/images/cards/general.png");
  const general1 = require("@/assets/images/cards/general-1.png");
  const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
  const unionpayCard = require("@/assets/images/cards/unionpay.svg");
  const directDebitCard = require("@/assets/images/cards/directdebit.svg");
  const payId = require("@/assets/images/cards/payid.svg");

  export default {
    components:{
      BankTransferIcon,
      CardPayIcon,
      DirectDebitIcon,
      ErrorMessage,
      PaymentMethodSummary,
      Popper,
      ToolTipIcon,
    },

    props: [
      "card_options",
      "directDebitLegals",
      "eftDetails",
      "feeAmount",
      "formData",
      "hidePayNow",
      "initialPayment",
      "paymentMethodDescription",
      "paymentMethods",
      "paymentRequestId",
      "page_type",
      "payLaterLegals",
      "payNowLegals",
      "productType",
      "purchaseAmount",
      "requestOption",
      "selectedPlan",
      "showDirectPayNowFromApiConfig",
      "showPayNow",
      "tillPublicKey",
      "total",
      "transactions"
    ],

    data() {
      return {
        accountNameValid: false,
        accountNumberValid: false,
        amex: amex,
        amexCard: amexCard,
        bsbError: true,
        bsbSuccess: false,
        cardFee: "",
        cardFocused: false,
        cardNameValid: false,
        cardSuccess: false,
        cardType: "default",
        card_status: {},
        cardValid: true,
        cardValidity: true,
        cvvSuccess: false,
        dateSuccess: false,
        dinersClubCard: dinersClubCard,
        directDebitCard: directDebitCard,
        displayErrorMessage: false,
        general: general,
        general1: general1,
        isCardAllowed: true,
        isInvalidCvv: false,
        masterCard: masterCard,
        payNowAcceptance: false,
        paymentJSObject: undefined,
        securityFocused: false,
        showBsbValid: true,
        showCardInputBox: true,
        showCardNumberIsRequired: false,
        showCvcIsRequired: false,
        showExpDateIsRequired: false,
        showExpired: false,
        showNameIsRequired: false,
        tabIndex: "",
        toc_acceptance: false,
        toc_acceptance_direct_debit: false,
        unionpayCard: unionpayCard,
        visaCard: visaCard,
        payId: payId,
        appName: (process.env.VUE_APP_NAME == "RelloPay") ? "Rello" : "FlipPay"
      }
    },

    computed: {
      getPayNowAmount() {
        let matchCardType = this.card_options.find((card_option) => {
          return card_option.card.slug === this.card_status.cardType;
        });

        let total = 0;

        if (matchCardType) {
          this.cardFee = (((this.formData.amount.$numberDecimal || this.formData.amount) / 100) * matchCardType.surcharge).toFixed(2);
          total = parseFloat(this.cardFee) + parseFloat(this.formData.amount.$numberDecimal||this.formData.amount);
        }

        if (matchCardType == undefined) {
          this.cardFee = 0;
          total = parseFloat(this.formData.amount.$numberDecimal||this.formData.amount);
        }

        return total;
      },

      feeCharge() {
        let fee = 0;

        if (this.requestOption == "pay-now") {
          fee = this.cardFee;
        } else {
          fee = this.feeAmount;
        }

        return fee;
      },

      totalCharge() {
        let total = 0;

        if (this.requestOption == "pay-now") {
          total = this.getPayNowAmount;
        } else {
          total = this.total;
        }

        return total;
      },

      SurChargable() {
        let isSurChargable = true;

        for (const card of this.card_options) {
          if (parseFloat(card.surcharge) > 0) {
            isSurChargable = false;
          }
        }

        return isSurChargable;
      },

      handlePayNowDisable() {
        let validPayment = true;
        if (this.formData.name == "" || this.formData.card_expiry_date == "" || !this.card_status.validCvv || !this.card_status.validNumber || !this.isCardAllowed || this.errors.has("cardHolder")) {
          validPayment = false;
        }

        return !validPayment || this.disablePayNow || !this.payNowAcceptance || this.showExpDateIsRequired || this.showExpired;
      },

      handlePayLaterDisable() {
        let validPayment = true;

        if (this.tabIndex == "CARD") {
          if (this.formData.name == "" || this.formData.card_expiry_date == "" || !this.card_status.validCvv || !this.card_status.validNumber || !this.isCardAllowed || this.errors.has("cardHolder")) {
            validPayment = false;
          }
          validPayment = this.disablePayLater || !this.toc_acceptance || !this.payNowAcceptance || !validPayment || this.showExpired || this.showExpDateIsRequired;
        } else if (this.tabIndex == "DIRECT_DEBIT") {
          if (this.formData.accountName == "" || this.formData.bsb == "" || this.formData.accountNumber == "" || this.errors.has("accountNumber")) {
            validPayment = false;
          }

          validPayment = !validPayment || this.disablePayLater || !this.toc_acceptance || !this.toc_acceptance_direct_debit || !this.showBsbValid;
        } else if (this.tabIndex == "EFT") {
          validPayment = !this.toc_acceptance;
        }

        return validPayment;
      },
    },

    methods: {
      ...mapActions("apiLog", ["storeApiLogRequest", "storeApiLogResponse"]),
      ...mapActions("payment", ["makePayment", "sendConfirmation"]),

      handleBsb() {
        const bsbData = this.formData.bsb.split("-");

        if (bsbData.length == 2 && bsbData[1].length == 3) {
          this.showBsbValid = true;
        } else {
          this.showBsbValid = false;
        }

        this.bsbSuccess = this.showBsbValid;
        this.bsbError = this.showBsbValid;
      },

      checkFieldValidation(field) {
        if (field == "cardName") {
          this.cardNameValid = this.formData.name.length > 1 && !this.errors.has("cardHolder");
        } else if (field == "accountName") {
          this.accountNameValid = this.formData.accountName.length > 1;
        } else if (field == "accountNumber") {
          this.accountNumberValid = this.formData.accountNumber.length > 1 && !this.errors.has("accountNumber");
        }
      },

      momentDate(date) {
        return moment(date).format("DD MMM YYYY");
      },

      checkExpiryDate(event) {
        const expiryDate = this.formData.card_expiry_date.split("/");
        const firstValue = expiryDate[0].charAt(0);

        if (firstValue >= 2) {
          this.formData.card_expiry_date = "0" + firstValue;
        }
        if (event.key == '/' && this.formData.card_expiry_date.length == 1) {
          this.formData.card_expiry_date = "0" + firstValue + "/";
        }

        if (expiryDate.length == 2) {
          const fullDate = `20${expiryDate[1]}-${expiryDate[0]}`;
          this.formData.exp_month = expiryDate[0];
          this.formData.exp_year = expiryDate[1];

          if (moment(fullDate).isSame(moment(new Date()).format("YYYY-MM"))) {
            return false;
          }
          return !moment(fullDate).isAfter(new Date());
        }

        return false;
      },

      async submitNormalOrRecurringPayment() {
        if (this.handlePayNowDisable) {
          return;
        }

        this.disablePayNow = true;
        this.showExpired = false;
        this.showCardNumberIsRequired = false;
        this.showCvcIsRequired = false;
        this.showNameIsRequired = false;
        this.showExpDateIsRequired = false;
        this.showExpDateIsRequired = false;
        this.displayErrorMessage = false;

        if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.numberLength == 0) {
          this.showCardNumberIsRequired = true;
          this.disablePayNow = false;
        }

        if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.cvvLength == 0) {
          this.showCvcIsRequired = true;
          this.disablePayNow = false;
        }

        const valid = await this.$validator.validate();

        if (!valid) {
          this.disablePayNow = false;
          this.showErrorMessage("Missing information", "Please check all fields and try again");
          return;
        }

        if (this.formData.name === "") {
          this.disablePayNow = false;
          return;
        }

        if (this.formData.card_expiry_date === "") {
          this.showExpDateIsRequired = true;
          this.disablePayNow = false;
          return;
        }
        if (this.showExpired) {
          this.disablePayNow = false;
          return;
        }

        if (this.card_status.validCvv && this.card_status.validNumber) {
          let exp_date = moment().set("year", this.formData.exp_year).set("month", this.formData.exp_month);
          exp_date = moment(exp_date, "MM-YYYY");
          this.formData.exp_date = exp_date._d;

          if (this.isCardAllowed) {
            this.interceptSubmit();
          } else {
            this.disablePayNow = false;
            this.showWarningMessage("Card", "Card type not allowed, please use one of the card types indicated below");
          }
        } else {
          this.disablePayNow = false;
          let message = this.card_status.validCvv === false
                ? "Please enter valid cvc."
                : this.card_status.validNumber === false
                ? "Please enter valid card number."
                : "Please enter valid card.";
          this.showWarningMessage("Card", message);
        }
      },

      async interceptSubmit() {
        let cardData = {
          card_holder: this.formData.name,
          month: this.formData.exp_month,
          year: this.formData.exp_year,
        };

        // FP-841: store card details sent to till
        await this.storeRequestDataSendedToTill(cardData);

        this.paymentJSObject.tokenize(cardData, this.submitPaymentForm, function (errors) {
          sentryLog("Payment js initialize error", { errors });
          //error callback function
          //render error information here
          this.showErrorMessage("Card", errors[0].message);

          // store response data from till
          if (this.requestUID) {
            const payload = {
              uid: this.requestUID,
              data: errors
            };
            this.storeApiLogResponse(payload);
          }
        });
      },

      submitPaymentForm(token, cardData) {
        // FP-841: store reponse data from till
        if (this.requestUID) {
          const payload = {
            uid: this.requestUID,
            data: {
              token,
              cardData
            }
          };
          this.storeApiLogResponse(payload);
        }

        this.disablePayNow = true;
        this.formData.transaction_token = token;
        this.formData.transaction_data = cardData;
        this.formData.periodLength = this.totalNumberOfPayments;
        this.formData.scheduleText = this.scheduleDescriptionArray;

        this.formData.payNowUsedCard = this.card_options.find((card_option) => {
          return card_option.card.slug === this.card_status.cardType;
        });

        this.formData.payment_mode = "now";
        this.$vs.loading();
        let phoneNumber = this.formData.maskedMobileNumber;
        this.formData.mobile = phoneNumber.split(" ").join("");

        const paymentFormData = new FormData();

        paymentFormData.append("formData", JSON.stringify(this.formData));

        let obj = {
          obj: paymentFormData,
          config: {
            header: {
              "Content-Type": "application/json",
            },
          },
        };

        this.makePayment(obj).then((res) => {
          this.$vs.loading.close();

          if (res.data.message == "") {
            return;
          }

          this.disablePayNow = false;
          this.showSuccessBox = true;
          this.hideCardWhenSuccess = false;
          (this.showCardInputBox = false), this.$vs.loading.close();

          if (res.data.data.success === false) {
            this.displayErrorMessage = true;
            this.disablePayNow = false;
            this.payNowConfirmationError = true;
            this.showConfimationForError = true;
            this.failureReason = res.data.data.errors[0].errorMessage;

            if (res.data.data.uuid) {
              this.till_failure_id_now = `TX-${res.data.data.uuid}`;
              this.till_failure_id = `TX-${res.data.data.uuid}`;
            }
          } else {
            this.showConfirmationForSuccess = true;
            this.showConfimationForError = false;
            this.till_response_uuid = `TX-${res.data.data.uuid}`;
            this.$emit("paymentSuccess", res.data.data);
            this.sendConfirmationForTransaction();
          }
        }, (error) => {
          this.$vs.loading.close();
          this.disablePayNow = false;
          this.payNowConfirmationError = true;
          this.showConfimationForError = true;
          this.failureReason = error.data.message;

          if (error.status == 500) {
            this.showErrorMessage("Payment", error.data.message);
          } else {
            this.displayErrorMessage = true;
          }
        });
      },

      async submitPayLaterPayment() {
        if (this.handlePayLaterDisable) {
          return;
        }

        this.disablePayLater = true;
        this.disableButtonAfterSubmit = true;
        this.showExpired = false;
        this.showCardNumberIsRequired = false;
        this.showCvcIsRequired = false;
        this.showNameIsRequired = false;
        this.showExpDateIsRequired = false;
        this.showExpDateIsRequired = false;
        this.displayErrorMessage = false;

        if (this.tabIndex == "CARD") {
          if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.numberLength == 0) {
            this.showCardNumberIsRequired = true;
            this.disablePayLater = false;
          }

          if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.cvvLength == 0) {
            this.showCvcIsRequired = true;
            this.disablePayLater = false;
          }
        }

        const valid = await this.$validator.validate();

        if (!valid) {
          this.disablePayLater = false;
          this.showWarningMessage("Missing information", "Please check all fields and try again");
          return;
        }

        if (this.formData.payment_method_type === "uuid") {
          this.sendDataFromUUID();
          return;
        }

        if (this.tabIndex == "CARD") {
          if (this.formData.name === "") {
            this.showNameIsRequired = true;
            this.disablePayLater = false;
          }

          if (this.formData.card_expiry_date === "") {
            this.showExpDateIsRequired = true;
            this.disablePayLater = false;
            return;
          }

          if (this.showExpired) {
            this.disablePayLater = false;
            return;
          }
        }

        this.formData.payment_mode = "later";

        if (this.tabIndex == "CARD" && this.card_status.validCvv === true && this.card_status.validNumber === true) {
          let exp_date = moment().set("year", this.formData.exp_year).set("month", this.formData.exp_month);
          exp_date = moment(exp_date, "MM-YYYY");
          this.formData.exp_date = exp_date._d;

          if (this.isCardAllowed) {
            this.submitDataForPayLater();
          } else {
            this.disablePayLater = false;
            this.showWarningMessage("Card", "Card type not allowed, please use one of the card types indicated below");
          }
        } else if (this.tabIndex == "DIRECT_DEBIT" && this.showBsbValid === true && this.accountNumberValid === true) {
          this.submitDebitDataForPayLater();
        } else if (this.paymentMethods.length == 1 && this.paymentMethods.includes("EFT")) {
          this.formData.customerSelectedPaymentMethod = "EFT";
          this.submitEFTForPayLater();
        } else {
          this.disablePayLater = false;
          let message = "Please enter valid details.";

          if (this.tabIndex == "CARD") {
            if (this.card_status.validCvv === false) {
              message = "Please enter valid cvc.";
            } else if (this.card_status.validNumber === false) {
              message = "Please enter valid card number.";
            }
          }

          this.showErrorMessage('Card', message);
        }
      },

      async submitDataForPayLater() {
        let self = this
        let newCardData = {
          card_holder: this.formData.name,
          month: this.formData.exp_month,
          year: this.formData.exp_year,
        };
        try {
          // FP-841: store card details sent to till
          await this.storeRequestDataSendedToTill(newCardData);

          await this.paymentJSObject.tokenize(
            newCardData,
            this.sendPayLaterData,
            function (errors) {
              self.showErrorMessage('Payment', errors[0].message);

              // store response data from till
              if (this.requestUID) {
                const payload = {
                  uid: this.requestUID,
                  data: errors
                };
                this.storeApiLogResponse(payload);
              }
            }
          );
        } catch(ex) {
          this.showWarningMessage('Payment', "Please reload the page. And try again.");
        }
      },

      async submitDebitDataForPayLater() {
        let self = this
        let paymentData = {
          account_holder: this.formData.accountName,
          bsb: this.formData.bsb,
          account_number: this.formData.accountNumber,
        };

        try {
          this.sendPayLaterData("", paymentData);
        } catch(ex) {
          this.showWarningMessage('Payment', "Please reload the page. And try again.");
        }
      },

      sendPayLaterData(token, cardData) {
        // store response data from till
        if (this.requestUID) {
          const payload = {
            uid: this.requestUID,
            data: {
              token,
              cardData
            }
          };
          this.storeApiLogResponse(payload);
        }

        if (token) {
          this.formData.transaction_token = token;
        }
        this.formData.transaction_data = cardData;
        this.formData.accountTypeId = this.formData.pay_later_type;
        this.formData.date_of_birth = this.formatDate(this.formData.date_of_birth);
        this.formData.licenseDetails.expiry_date = this.formatDate(this.formData.licenseDetails.expiry_date);
        this.formData.passportDetails.issue_date = this.formatDate(this.formData.passportDetails.issue_date);
        this.formData.passportDetails.expiry_date = this.formatDate(this.formData.passportDetails.expiry_date);

        const paymentFormData = new FormData();

        let phoneNumber = this.formData.maskedMobileNumber;
        this.formData.mobile = phoneNumber.split(" ").join("");
        paymentFormData.append("formData", JSON.stringify(this.formData));

        let obj = {
          obj: paymentFormData,
          config: {
            header: {
              "Content-Type": "application/json",
            },
          },
        };

        this.$vs.loading();

        this.makePayment(obj).then((res) => {
          this.$vs.loading.close();

          if (res.data.message == "") {
            return;
          }

          if (["Active", "Submitted"].includes(res.data.data.paymentStatus)) {
            this.showConfirmationForPaylaterSuccess = true;
            this.showConfirmationForPayLaterError = false;
            this.disablePayLater = true;
            this.hideCardWhenSuccess = false;
            this.showSuccessBox = true;
            this.$emit("paymentSuccess", res.data.data);
          } else {
            this.displayErrorMessage = true;
            this.payLaterConfirmationError = true;
            this.showConfirmationForPayLaterError = true;
            this.failureReason = res.data.data.Message;
            if (res.data.data.uuid) {
              this.till_failure_id_later = `TX-${res.data.data.uuid}`;
              this.till_failure_id = `TX-${res.data.data.uuid}`;
            }
          }
        }, (error) => {
          if (error.status == 422)  {
            error.data.data.forEach(item => {
              if (!this.errors.items.find(error => error.field == item.field)) {
                this.errors.add(item);
              }
            });
          }

          this.disablePayLater = false;
          this.$vs.loading.close();
          this.payLaterConfirmationError = true;
          this.showConfirmationForPayLaterError = true;
          this.failureReason = error.data.message;

          if (error.data.data && error.data.data.uuid) {
            this.till_failure_id_later = `TX-${error.data.data.uuid}`;
            this.till_failure_id = `TX-${error.data.data.uuid}`;
          }

          this.paylaterFailurePayload = error.data;

          if (error.status == 500) {
            this.showErrorMessage("Payment", error.data.message);
          } else {
            this.displayErrorMessage = true;
          }
        });
      },

      sendConfirmationForTransaction() {
        let confirmationData = {};
        confirmationData.paymentRequestId = this.formData.paymentRequestId;
        confirmationData.email = this.formData.email;

        let phoneNumber = this.formData.maskedMobileNumber;
        this.formData.mobile = phoneNumber.split(" ").join("");

        confirmationData.mobile = this.formData.mobile;
        confirmationData.sendConfirmText = this.formData.sendConfirmText;
        this.$vs.loading();
        this.sendConfirmation(confirmationData).then(
          (_res) => {
            this.$vs.loading.close();
          },
          (error) => {
            this.$vs.loading.close();
            this.showErrorMessage('Payment', error.data.message);
          }
        );
      },

      formatDate(date) {
        let d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2) {
          month = "0" + month;
        }

        if (day.length < 2) {
          day = "0" + day;
        }

        return [year, month, day].join("-");
      },

      acceptPayNowTerms() {
        this.payNowAcceptance = !this.payNowAcceptance;
      },

      acceptTerms() {
        this.toc_acceptance = !this.toc_acceptance;
      },

      acceptTermsNPP() {
        this.toc_acceptance_direct_debit = !this.toc_acceptance_direct_debit;
      },

      handleExpiryCardField(event) {
        const value = event.target.value;
        this.showExpDateIsRequired = (value.length === 0);
        this.checkExpiryDate(event);
        if (value.length < 5) {
          this.dateSuccess = false;
          return;
        }
        this.showExpired = this.checkExpiryDate(event);
        this.dateSuccess = (value.length == 5 && !this.showExpired);
      },

      handleExpiryCardFieldOnBlur(event) {
        if (event.target.value.length < 5) {
          this.showExpired = true;
          this.dateSuccess = false;
        }
      },

      selectTab(index) {
        this.tabIndex = index;
        this.formData.customerSelectedPaymentMethod = index;

        if (this.tabIndex == "CARD") {
          this.paymentJSObject = undefined;
          this.initializePaymentObject();
          this.cvvSuccess = false;
          this.cardNameValid = false;
          this.isInvalidCvv = false;
          this.formData.name = "";
          this.formData.card_expiry_date = "";
          this.formData.exp_month = "";
          this.formData.exp_year = "";
          this.showCardNumberIsRequired = false;
          this.isCardAllowed = true;
          this.cardValid = true;
          this.showCvcIsRequired = false;
          this.showExpired = false;
          this.showExpDateIsRequired = false;
          this.dateSuccess = false;
          this.dateError = true;
          this.cardType = "default";
          this.cardValidity = true;
          this.cardSuccess = false;
        } else if (this.tabIndex === "DIRECT_DEBIT") {
          this.bsbSuccess = false;
          this.accountNameValid = false;
          this.accountNumberValid = false;
          this.formData.accountName = "";
          this.formData.bsb = "";
          this.formData.accountNumber = "";
          this.showBsbValid = true;
          this.bsbError = true;
          this.toc_acceptance_direct_debit = false;
        }

        this.payNowAcceptance = false;
        this.toc_acceptance = false;
        this.displayErrorMessage = false;
      },

      addZeroes(num) {
        num = String(num);
        return Number(num).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        });
      },

      async initializePaymentObject() {
        try {
          // eslint-disable-next-line no-undef
          if (this.paymentJSObject != undefined) {
            delete this.paymentJSObject;
            return;
          } else {
            this.paymentJSObject = new PaymentJs();
            await this.paymentJSObject.init(this.tillPublicKey, "number_div", "cvv_div", this.setPaymentStyle);
          }
        } catch(e) {
          console.log("Error initialization payment JS", e);
          sentryLog(e);
        }
      },

      setPaymentStyle(payment) {
        payment.setNumberStyle({
          "width": "100%",
          "border": "none",
          "box-sizing": "border-box",
          "height": "28px",
          "outline": 0,
          "font-family": "Arial",
          "font-size": "14px",
          "color": "#0D0F11",
          "padding": "0 8px",
          "background" : "none"
        });
        payment.setCvvStyle({
          "width": "100%",
          "border": "none",
          "outline": 0,
          "font-family": "Arial",
          "box-sizing": "border-box",
          "height": "28px",
          "font-size": "14px",
          "color": "#0D0F11",
          "padding": "0 8px",
          "background" : "none"
        });
        payment.numberOn("input", this.checkCardValidity);
        payment.cvvOn("input", this.checkCvvValidity);
        payment.setNumberPlaceholder("0000 0000 0000 0000");
        payment.numberOn('focus', this.handleCardFocus);
        payment.cvvOn('focus', this.handleCvvFocus);
        payment.numberOn('blur', this.handleCardBlur);
        payment.cvvOn('blur', this.handleCvvBlur);
      },

      checkCardValidity(data) {
        this.card_status = data;
        this.cardValid = true;
        if (this.card_status.numberLength == 0) {
          this.showCardNumberIsRequired = true;
          this.cardType = "";
        }

        if (this.card_status.numberLength > 0) {
          this.showCardNumberIsRequired = false;
        }

        let checkCardAllowedStatus = this.card_options.find((card_option) => {
          return card_option.card.slug === data.cardType;
        });

        if (data.cardType) {
          this.cardType = data.cardType;
          this.cardValidity = data.validNumber;
          this.cardSuccess = data.validNumber && checkCardAllowedStatus;
          if (!checkCardAllowedStatus) {
            this.isCardAllowed = false;
          } else {
            this.isCardAllowed = true;
          }
        } else {
          this.cardSuccess = false;
        }
      },

      checkCvvValidity(data) {
        this.card_status = data;
        this.cvvSuccess = data.validCvv;
        if (this.card_status.cvvLength == 0) {
          this.showCvcIsRequired = true;
        }
        if (this.card_status.cvvLength > 0) {
          this.showCvcIsRequired = false;
        }
        this.isInvalidCvv = !data.validCvv;
      },

      handleCardBlur() {
        this.cardValid = this.cardValidity;
        this.cardFocused = false;
      },

      handleCardFocus() {
        this.cardFocused = true;
      },

      handleCvvBlur() {
        this.securityFocused = false;
      },

      handleCvvFocus() {
        this.securityFocused = true;
      },

      // FP-841: store card details sent to till
      async storeRequestDataSendedToTill (cardData) {
        const payload = {
          cardDetail: { ...cardData, ...this.card_status },
          provider: "Till",
          url: "https://secure.tillpayments.com/v1/{{TOKEN}}/tokenize/creditcard",
          paymentRequestId: this.paymentRID,
        }

        const apiLogRequest = await this.storeApiLogRequest(payload);

        if (apiLogRequest) {
          this.requestUID = apiLogRequest.data.data;
        }
      },

      showSuccessMessage(title = "Success", message = "Success"){
        this.$toast.success(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
          position: "top-right",
          timeout: 5000,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons text-success",
            iconChildren: "check_circle_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message
        });
      },

      showErrorMessage(title = "Error", message="Something went wrong.", time = false){
        this.$toast.error(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
          position: "bottom-left",
          timeout: time ? 3000 : false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons text-danger",
            iconChildren: "highlight_off",
            iconTag: "span"
          },
          rtl: false,
          message: message
        });
      },

      showWarningMessage(title = "Warning", message="Warning", time = false){
        this.$toast.warning(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
          position: "top-right",
          timeout: time ? 3000 : false,
          closeOnClick: false,
          pauseOnFocusLoss: false,
          pauseOnHover: false,
          draggable: false,
          draggablePercent: 0.1,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: {
            iconClass: "material-icons text-warning",
            iconChildren: "info_outline",
            iconTag: "span"
          },
          rtl: false,
          message: message
        });
      },

      async submitEFTForPayLater() {
        try {
          this.sendPayLaterData("", {});
        } catch(ex) {
          this.showWarningMessage('Payment', "We are unable to process your request at the moment. Please try again in a few minutes.");
        }
      },


      sendDataFromUUID() {
        this.formData.payment_mode = "later";
        this.formData.transaction_data = {card_type: "visa"};
        this.formData.paymentRequestId = this.paymentRequestId;
        this.formData.accountTypeId = this.formData.pay_later_type;
        this.formData.date_of_birth = this.formatDate(this.formData.date_of_birth);
        this.formData.licenseDetails.expiry_date = this.formatDate(this.formData.licenseDetails.expiry_date);
        this.formData.passportDetails.issue_date = this.formatDate(this.formData.passportDetails.issue_date);
        this.formData.passportDetails.expiry_date = this.formatDate(this.formData.passportDetails.expiry_date);

        let phoneNumber = this.formData.maskedMobileNumber;
        this.formData.mobile = phoneNumber.split(" ").join("");

        const paymentFormData = new FormData();
        paymentFormData.append("formData", JSON.stringify(this.formData));

        let obj = {
          obj: paymentFormData,
          config: {
            header: {
              "Content-Type": "application/json",
            },
          },
        };

        this.$vs.loading();
        this.makePayment(obj).then(
          (res) => {
            this.$vs.loading.close();

            if (res.data.message == '') {
              return;
            }

            if (["Active", "Submitted"].includes(res.data.data.paymentStatus )) {
              this.showConfirmationForPaylaterSuccess = true;
              this.showConfirmationForPayLaterError = false;
              this.disablePayLater = true;
            } else {
              this.showErrorMessage('Payment', res.data.data.Message);
              this.payLaterConfirmationError = true;
              this.showConfirmationForPayLaterError = true;
              this.failureReason = res.data.data.Message;
              if (res.data.data.uuid) {
                this.till_failure_id_later = `TX-${res.data.data.uuid}`;
                this.till_failure_id = `TX-${res.data.data.uuid}`;
              }
            }
          },
          (error) => {
            this.disablePayLater = false;
            this.$vs.loading.close();
            this.showErrorMessage('Payment', error.data.message);
            this.payLaterConfirmationError = true;
            this.showConfirmationForPayLaterError = true;
            this.failureReason = error.data.message;
            this.paylaterFailurePayload = error.data;

            if (error.data.data && error.data.data.uuid) {
              this.till_failure_id_later = `TX-${error.data.data.uuid}`;
              this.till_failure_id = `TX-${error.data.data.uuid}`;
            }
          }
        );
      },
    },

    mounted() {
      this.selectTab(this.paymentMethods[0]);
    },

    directives: {
      mask
    },
  }
</script>